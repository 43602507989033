import PartiesCommitmentsSection from './PartiesCommitmentsSection';
import ProductDescriptionSection from './ProductDescriptionSection';

const Page2 = ({ data }) => {
  return (
    <>
      <PartiesCommitmentsSection />
      <ProductDescriptionSection data={data} />
    </>
  );
};

export default Page2;
