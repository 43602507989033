import ComplainSection from './ComplainSection';
import InformationSectionPart2 from './InformationSectionPart2';

const NewPolicyPage6 = () => {
  return (
    <>
      <InformationSectionPart2 />
      <ComplainSection />
    </>
  );
};

export default NewPolicyPage6;
